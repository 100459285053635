
import { defineComponent } from 'vue';
import DoorWrapper from '../DoorWrapper.vue';
import LeadfamilyGame from '@/views/LeadfamilyGame.vue';

const WOF_IDS = [97358,  99149, 99160, 99164];
const SCRATCH_IDS = [99204, 97377, 99175, 99176];
const RPS_IDS = [97439, 99199, 99203];

/* There will be additional iframe handling for these in the future, if iframe content change */
/*
const DICE_IDS = [97371, 99171, 99202];
const SLOT_MACHINE_IDS = [97385, 99179, 99189, 99188];
*/

export default defineComponent({
  components: {
    DoorWrapper,
    LeadfamilyGame,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    gameId: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    gameCssClass() {
      if (WOF_IDS.includes(this.gameId)) return 'wof';
      else if (SCRATCH_IDS.includes(this.gameId)) return 'scratch';
      else if (RPS_IDS.includes(this.gameId)) return 'rps';
      return 'default';
    },
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
  },
});
