import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ce76f5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed w-full flex justify-center md:block md:w-auto bottom-0 md:right-0" }
const _hoisted_2 = { class: "survey bg-primary-turquoise text-primary-soft-white md:mr-11 px-6 py-4 md:px-0 flex flex-wrap flex-col items-center relative" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBtn = _resolveComponent("CloseBtn")!
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CloseBtn, {
        light: "",
        small: "",
        onClose: _ctx.closeSurvey
      }, null, 8, ["onClose"]),
      _createElementVNode("div", {
        class: "thumb my-4 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openSurvey && _ctx.openSurvey(...args)))
      }, [
        _createVNode(_component_LottieAnimation, {
          autoPlay: true,
          animationData: require('../assets/animation/survey.json'),
          loop: true
        }, null, 8, ["animationData"])
      ]),
      _createElementVNode("h2", {
        class: "font-bold text-center px-2 md:px-0 cursor-pointer",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openSurvey && _ctx.openSurvey(...args))),
        innerHTML: _ctx.title
      }, null, 8, _hoisted_3),
      _createElementVNode("p", {
        class: "text-center md:px-9 cursor-pointer",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openSurvey && _ctx.openSurvey(...args))),
        innerHTML: _ctx.text
      }, null, 8, _hoisted_4),
      _createElementVNode("div", {
        class: "w-12 h-12 rounded-full bg-primary-soft-white flex justify-center items-center mt-4",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openSurvey && _ctx.openSurvey(...args)))
      }, [
        _createElementVNode("img", {
          src: require('@/modules/advent/assets/img/dropdown.svg'),
          class: "w-5"
        }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}