import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cefe898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "door-wrapper text-primary-slate min-h-screen h-auto flex flex-1 flex-col content-end w-full lg:flex-none lg:flex-row lg:content-start" }
const _hoisted_2 = {
  key: 0,
  class: "hidden h-full lg:block lg:w-1/4 xl:overflow-y-hidden"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "hidden h-full lg:block lg:w-1/4 xl:overflow-y-hidden"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBtn = _resolveComponent("CloseBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.desktopBG.left)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.desktopBG.left,
            class: "h-full object-cover"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.fullSize ? '' : 'lg:px-24', "door-content-box bg-primary-soft-white flex flex-col items-center py-9 px-4 md:px-8 lg:w-2/4 mt-auto lg:mt-0 relative"])
    }, [
      _createVNode(_component_CloseBtn, { onClose: _ctx.closeDoor }, null, 8, ["onClose"]),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.fullSize ? 'w-full md:w-full' : 'md:w-2/3 ', "door-content lg:w-full h-full"])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ], 2),
    (_ctx.desktopBG.right)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.desktopBG.right,
            class: "h-full object-cover"
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ]))
}