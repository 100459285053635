
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from './DoorWrapper.vue';
import { Door } from '../types';

export default defineComponent({
  components: {
    Button,
    DoorWrapper,
  },
  emits: ['close'],
  data() {
    return {
      t: null,
      door: {} as Door,
    };
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  mounted() {
    this.door = this.$store.state.adventModule.door;
    if (this.door.points == '') this.$emit('close');
  },
  unmounted() {
    this.$store.commit('adventModule/SET_POINTS', '');
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
  },
});
