
import { defineComponent } from 'vue';
import CloseBtn from './Helpers/CloseBtn.vue';
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import { useStore } from 'vuex';

export default defineComponent({
  components: { CloseBtn, LottieAnimation: Vue3Lottie },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    href: {
      type: String,
    },
  },
  emits: ['close'],
  setup() {
    const store = useStore();

    const consumerId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    return {
      token,
      consumerId,
    }
  },
  methods: {
    closeSurvey() {
      this.$emit('close');
    },
    openSurvey() {
      window.open(this.href + `?person_id=${this.consumerId}&token=${this.token}`, '_blank');
    },
  },
});
