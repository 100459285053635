import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_BackToClubButton = _resolveComponent("BackToClubButton")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!

  return (_openBlock(), _createBlock(_component_ContentWrapper, null, {
    outer: _withCtx(() => [
      _createVNode(_component_BackToClubButton)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ContentBlock, {
        class: "text-center",
        title: "Wir packen gerade Eure Geschenke ein.",
        text: 
        `Schaue Dir in der Zwischenzeit in unseren Stories an,
        was die IQOS Community in diesem Jahr erlebt hat und sichere
        Dir 30 Statuspunkte.`
      ,
        a: _ctx.t?.maintenance.a,
        cssClass: "maintenance",
        button: ""
      }, null, 8, ["text", "a"])
    ]),
    _: 1
  }))
}