
import { defineComponent, PropType } from 'vue';
import Button from '@/components/Button/Button.vue';
import CustomInput from '@/components/Input/Input.vue';
import DoorWrapper from '../DoorWrapper.vue';
import { Door } from '../../types';
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';

export default defineComponent({
  components: {
    Button,
    CustomInput,
    DoorWrapper,
    LottieAnimation: Vue3Lottie,
  },
  props: {
    door: {
      type: Object as PropType<Door>,
      required: true,
    },
  },
  emits: ['close', 'accept'],
  data() {
    return {
      errormsg: [] as Array<string>,
      topic: '' as string,
      poem: '' as string,
      step: 0 as number,
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  methods: {
    closeDoor(): void {
      this.$emit('close');
    },
    nextStep(): void {
      if (this.step === 1) {
        this.createPoem();
      } else {
        this.step++;
      }
    },
    accept(): void {
      this.$emit('accept');
    },
    createPoem(): void {
      this.step++;
      this.$store
        .dispatch('adventModule/getPoem', this.topic)
        .then(() => {
          this.poem = this.$store.state.adventModule.poem;
          this.step++;
        })
        .catch((e) => {
          this.errormsg = e;
          this.step--;
        });
    },
  },
});
