
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import ContentBlock from '../components/ContentBlock.vue';
import Survey from '../components/Survey.vue';

export default defineComponent({
  components: { Button, ContentBlock, Survey },
  data() {
    return {
      t: null,
      showSurvey: true,
      shareData: {} as ShareData,
      webShareCapable: false as boolean,
    };
  },
  computed: {
    isDesktop() {
      return this.$mq !== 'sm' && this.$mq !== 'md' && this.$mq !== 'lg';
    },
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  mounted() {
    this.getShareImg();
  },

  methods: {
    closeSurvey() {
      this.showSurvey = false;
    },
    async getShareImg() {
      fetch('../adventcard/postcard_16x9_desktop.png').then((i) => {
        i.blob().then((b) => {
          const sharefile = new File([b], 'IQOS-Advent-Postcard.png', {
            type: 'image/png',
          });
          this.shareData = {
            title: this.t.card[0].buttons[1].title || 'Frohe Weihnachten!',
            text: this.t.card[0].buttons[1].message,
            files: [sharefile],
          };
          this.webShareCapable = navigator.canShare(this.shareData);
        });
      });
    },
    webShare() {
      navigator
        .share(this.shareData)
        .then(() => console.log('Share was successful.'))
        .catch((error) => console.log('Sharing failed', error));
    },
  },
});
