
import { defineComponent } from 'vue';
import ContentWrapper from '@/modules/advent2023/components/ContentWrapper.vue';
import ContentBlock from '@/modules/advent2023/components/ContentBlock.vue';
import BackToClubButton from '@/modules/advent2023/components/BackToClubButton.vue';

export default defineComponent({
  components: {
    ContentWrapper,
    ContentBlock,
    BackToClubButton,
  },
  data() {
    return {
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
});
