import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e28658c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full flex flex-col items-center justify-center space-y-8" }
const _hoisted_2 = {
  key: 0,
  class: "copy-wrapper--big"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 3 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex justify-around w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DoorWrapper = _resolveComponent("DoorWrapper")!

  return (_openBlock(), _createBlock(_component_DoorWrapper, { onClose: _ctx.closeDoor }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.poem)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_2, [
              _createElementVNode("pre", null, _toDisplayString(_ctx.poem), 1)
            ]))
          : (_openBlock(), _createElementBlock("h1", {
              key: 1,
              class: "copy-wrapper--big",
              innerHTML: _ctx.t?.doors.cocreation[_ctx.step].cp || _ctx.door?.header
            }, null, 8, _hoisted_3)),
        (_ctx.step === 1)
          ? (_openBlock(), _createBlock(_component_CustomInput, {
              key: 2,
              modelValue: _ctx.topic,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.topic) = $event)),
              label: _ctx.t?.doors.cocreation[1].label,
              showLabel: false,
              errorMessages: _ctx.errormsg
            }, null, 8, ["modelValue", "label", "errorMessages"]))
          : (_ctx.step === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createVNode(_component_LottieAnimation, {
                    autoPlay: true,
                    animationData: require('../../assets/animation/poem.json'),
                    loop: true
                  }, null, 8, ["animationData"])
                ]),
                _createElementVNode("p", {
                  class: "copy text-center copy-wrapper my-10",
                  innerHTML: _ctx.t?.doors.cocreation[2].hint
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.step < 2)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                disabled: _ctx.step === 1 && !_ctx.topic,
                class: "w-48",
                theme: "light",
                outline: "",
                onClick: _ctx.nextStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t?.doors.cocreation[_ctx.step].a), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : (_ctx.step === 3)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  class: "w-48",
                  theme: "light",
                  outline: "",
                  onClick: _ctx.accept
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t?.doors.cocreation[_ctx.step].a[0].cp), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
          (_ctx.step === 3)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 2,
                class: "w-48",
                theme: "dark",
                outline: "",
                alternateHover: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step = 1))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t?.doors.cocreation[_ctx.step].a[1].cp), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}