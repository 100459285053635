
import { defineComponent } from 'vue';
import CloseBtn from './CloseBtn.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

import { generateResponsiveImage } from '@/modules/advent2023/utils'

export default defineComponent({
  components: {
    CloseBtn,
    ResponsiveImage,
  },
  props: {
    fullSize: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      backgroundImage: generateResponsiveImage('background', 'background/keyvisual-right'),
      challengeBackgroundImage: generateResponsiveImage('challenge-background', 'background/bg-xmas-l', 'background/bg-xmas-s-copy'),
    };
  },
  computed: {
    isDesktop() {
      return this.$mq !== 'sm' && this.$mq !== 'md' && this.$mq !== 'lg';
    },
    desktopBG() {
      return {
        left: this.isDesktop ? require('@/modules/advent/assets/img/background/door-bg-L-left.jpg') : '',
        right: this.isDesktop ? require('@/modules/advent/assets/img/background/door-bg-L-right.jpg') : '',
      }
    },
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
  },
});
