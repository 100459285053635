
import { defineComponent } from 'vue';
import { Door } from '../types';
import InlineSvg from 'vue-inline-svg';
import DoorOverlay from './DoorOverlay.vue';
import DoorCoCreation from './DoorTypes/CoCreation.vue';
import DoorLeadFamily from './DoorTypes/LeadFamily.vue';
import DoorMGM from './DoorTypes/MGM.vue';
import DoorPodcast from './DoorTypes/Podcast.vue';
import DoorWyng from './DoorTypes/Wyng.vue';
import Prices from './Prices.vue';
import { Vue3Lottie } from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';
import animation1 from '../assets/animation/animation1.json';
import animation2 from '../assets/animation/animation2.json';
import animation3 from '../assets/animation/animation3.json';
import animation4 from '../assets/animation/animation4.json';
import animation5 from '../assets/animation/animation5.json';
import animation6 from '../assets/animation/animation6.json';

export default defineComponent({
  components: {
    DoorOverlay,
    DoorCoCreation,
    DoorLeadFamily,
    DoorMGM,
    DoorPodcast,
    DoorWyng,
    InlineSvg,
    LottieAnimation: Vue3Lottie,
    Prices,
  },
  emits: ['toggleFullscreen'],
  data() {
    return {
      animation: {
        show: false as boolean,
        x: 0 as number,
        y: 0 as number,
        w: 0 as number,
        h: 0 as number,
        clip: 5 as number,
      },
      prices: false as boolean,
      /* Testing Tage 25-30 aus dem Array entfernen */
      calendarSections: [
        [5, 13, 21, 17, 20, 12],
        [23, 3, 1, 7, 16, 11, 8, 25, 26, 27, 28],
        [4, 18, 14, 6, 2, 10, 29, 30],
        [24, 9, 15, 19, 22],
      ] as Array<Array<number>>,
      calendarGraphic: '' as string,
      today: new Date().getDate() as number,
      door: {
        action: false,
        open: false,
        day: 1,
      } as Door,
      hoverState: null as string | null,
      animations: [
        animation1,
        animation2,
        animation3,
        animation4,
        animation5,
        animation6,
      ] as Array<unknown>,
    };
  },
  computed: {
    showCalendar() {
      return !!!this.door?.open && !!!this.door?.action && !!!this.prices;
    },
  },
  created() {
    this.$store.dispatch('adventModule/getCalendar');
  },
  mounted() {
    this.loadCalendarGraphic();
    window.addEventListener('resize', this.loadCalendarGraphic);
  },
  unmounted() {
    window.removeEventListener('resize', this.loadCalendarGraphic);
  },
  methods: {
    clickDoor(e: MouseEvent): void {
      const tile = this.checkTile(e);
      const currentDay = new Date().getDate() as number;
      if ( tile && Number(tile.id.split('_')[1]) === currentDay ){
        this.today = Number(tile.id.split('_')[1]);
        this.$store.dispatch('adventModule/openDoor', this.today).then(() => {
          this.door = { ...this.door, ...this.$store.state.adventModule.door };
          this.door.day = this.today;
          /* Center animation on selected tile */
          const cw = this.$refs.calendar.offsetWidth;
          const ch = this.$refs.calendar.offsetHeight;
          const tw =
            (cw / (/_m\d{1}/.exec(this.calendarGraphic) ? 450.6 : 1890.8)) * 90; //tileWidth, tiles are square => width = height, mobile tilesize < desktop
          this.animation.w = cw * 4;
          this.animation.h = ch * 4;
          this.animation.x = Math.floor(e.offsetX / tw) * tw - 2 * cw + tw / 2;
          this.animation.y = Math.floor(e.offsetY / tw) * tw - 2 * ch + tw / 2;
          /* Play animation */
          this.animation.clip = (this.door.animation.slice(-1) as number) - 1;
          this.animation.show = true;
        });
      }
    },
    checkTile(e: Event): EventTarget {
      return e.composedPath().find((b: HTMLElement) => b.id?.startsWith('Tür'));
    },
    hoverDoor(e: MouseEvent): void {
      const tile = this.checkTile(e);
      if (tile) {
        const h = Array.prototype.find.call(tile.children, ({ id }) =>
          id.startsWith('Hover')
        );
        h.style.opacity = '1';
        this.hoverState = h.id;
        return;
      }
      if (!!this.hoverState)
        document.getElementById(this.hoverState).style.opacity = '0';
      this.hoverState = null;
    },
    toggleDoor(state: boolean): void {
      this.animation.show = false;
      this.door.open = state;
      this.toggleFullScreen(state);
    },
    toggleAction(state: boolean): void {
      this.door.action = state;
      this.toggleFullScreen();
    },
    togglePrices(state: boolean): void {
      this.prices = state;
      this.toggleFullScreen();
    },
    toggleFullScreen() {
      this.$emit('toggleFullscreen', !this.showCalendar);
    },
    loadCalendarGraphic() {
      /* Load calendar SVG based on window size */
      this.calendarGraphic = '';
      this.calendarGraphic =
        window.innerWidth < 1024
          ? `calendar_m${
              this.calendarSections.findIndex((g) =>
                g.find((e) => e === this.today)
              ) + 1
            }`
          : 'calendar';
    },
  },
});
