
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
