
import { defineComponent } from 'vue';
import CloseBtn from './Helpers/CloseBtn.vue';

export default defineComponent({
  components: {
    CloseBtn,
  },
  props: {
    fullSize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    isDesktop() {
      return this.$mq !== 'sm' && this.$mq !== 'md' && this.$mq !== 'lg';
    },
    desktopBG() {
      return {
        left: this.isDesktop ? require('@/modules/advent/assets/img/background/door-bg-L-left.jpg') : '',
        right: this.isDesktop ? require('@/modules/advent/assets/img/background/door-bg-L-right.jpg') : '',
      }
    },
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
  },
});
