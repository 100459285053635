import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-236e5fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full flex flex-col items-center justify-center space-y-2" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex flex-col items-center justify-center text-primary-turquoise font-bold" }
const _hoisted_5 = { class: "text-9xl py-2" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DoorWrapper = _resolveComponent("DoorWrapper")!

  return (_openBlock(), _createBlock(_component_DoorWrapper, { onClose: _ctx.closeDoor }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "h-16 object-cover object-left",
          src: require('../assets/img/rewards.svg')
        }, null, 8, _hoisted_2),
        _createElementVNode("h1", {
          class: "heading-2 copy-wrapper--big font-bold text-center",
          innerHTML: _ctx.t?.prices.hl
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.door.points), 1),
          _createElementVNode("div", {
            class: "points",
            innerHTML: _ctx.t?.prices.points
          }, null, 8, _hoisted_6)
        ]),
        _createElementVNode("p", {
          class: "copy--responsive copy-wrapper--big text-center py-4",
          innerHTML: _ctx.t?.prices.cp
        }, null, 8, _hoisted_7),
        _createVNode(_component_Button, {
          theme: "light",
          href: _ctx.t?.prices.a.href
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t?.prices.a.cp), 1)
          ]),
          _: 1
        }, 8, ["href"])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}