
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from '../DoorWrapper.vue';

export default defineComponent({
  components: {
    DoorWrapper,
    Button,
  },
  props: {
    door: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'points'],
  data() {
    return {
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
    copyCode() {
      navigator.clipboard.writeText(this.door.mgm);
    },
    skip() {
      if (this.$store.state.adventModule.door.points !== '') {
        this.$emit('points');
      } else {
        window.open(this.t.doors.mgm.skip_href, '_self');
      }
    },
    addCodeToLink(link: string) {
      return link.replace('{mgmcode}', this.door.mgm);
    },
  },
});
