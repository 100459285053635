
import { defineComponent, PropType } from 'vue';
import { Video } from '@/types';
import { Door } from '../../types';
import DoorWrapper from '../DoorWrapper.vue';
import VideoWithProgressTracking from '@/components/VideoWithProgressTracking/VideoWithProgressTracking.vue';

export default defineComponent({
  components: {
    VideoWithProgressTracking,
    DoorWrapper,
  },
  props: {
    door: {
      type: Object as PropType<Door>,
      required: true,
    },
  },
  emits: ['close', 'points'],
  data() {
    return {
      points: false,
      hasStartedPlaying: false,
      isPaused: true,
      t: null,
      video: {} as Video,
    };
  },
  computed: {
    CTA() {
      return this.isPaused
        ? this.t.doors.video.play
        : this.t.doors.video.pause;
    },
    videoNode() {
      return this.$refs?.refVideoComponent.refVideoPlayer;
    },
    isVideoViewedCompletely() {
      return Object.values(this.video.progress).every(x => x)
    }
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
  mounted() {
    (this.video as Video) = {
      videoUrl: `${this.door.url}#t=0.001`,
      name: this.door.header,
      trackingAction: this.door.action_id,
      uid: this.door.header,
      image: require('@/modules/advent/assets/img/together-do.svg'),
      progress: {
        10: false,
        20: false,
        30: false,
        40: false,
        50: false,
        60: false,
        70: false,
        80: false,
        90: false,
        100: false,
      }
    };
  },
  unmounted() {
    this.points = false;
  },
  methods: {
    closeDoor(): void {
      this.$emit('close');
    },
    startPlay(): void {
      this.hasStartedPlaying = true;
      this.videoNode.play()
    },
    trackProgress({ progress }): void {
      this.video.progress[progress] = true;
      if(this.isVideoViewedCompletely) {
        this.$emit('points');
      }
    }
  },
});
