import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  ref: "calendar",
  class: "text-2xl font-bold h-full w-full inset-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_DoorOverlay = _resolveComponent("DoorOverlay")!
  const _component_DoorCoCreation = _resolveComponent("DoorCoCreation")!
  const _component_DoorLeadFamily = _resolveComponent("DoorLeadFamily")!
  const _component_DoorMGM = _resolveComponent("DoorMGM")!
  const _component_DoorPodcast = _resolveComponent("DoorPodcast")!
  const _component_DoorWyng = _resolveComponent("DoorWyng")!
  const _component_Prices = _resolveComponent("Prices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCalendar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.calendarGraphic)
            ? (_openBlock(), _createBlock(_component_InlineSvg, {
                key: 0,
                class: _normalizeClass(_ctx.hoverState ? 'cursor-pointer' : ''),
                src: require(`../assets/img/${_ctx.calendarGraphic}.svg`),
                onClick: _ctx.clickDoor,
                onMousemove: _ctx.hoverDoor
              }, null, 8, ["class", "src", "onClick", "onMousemove"]))
            : _createCommentVNode("", true)
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.animation.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "absolute z-50",
          style: _normalizeStyle({
        top: `${_ctx.animation.y}px`,
        left: `${_ctx.animation.x}px`,
        width: `${_ctx.animation.w}px`,
        height: `${_ctx.animation.h}px`,
      })
        }, [
          _createVNode(_component_LottieAnimation, {
            ref: "doorAnimation",
            class: "inset-0",
            autoPlay: true,
            animationData: _ctx.animations[_ctx.animation.clip],
            loop: false,
            onOnComplete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDoor(true)))
          }, null, 8, ["animationData"])
        ], 4))
      : _createCommentVNode("", true),
    (!!_ctx.door.open)
      ? (_openBlock(), _createBlock(_component_DoorOverlay, {
          key: 2,
          door: _ctx.door,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDoor(false))),
          onAction: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.toggleAction(true);
        _ctx.toggleDoor(false);
      })
        }, null, 8, ["door"]))
      : _createCommentVNode("", true),
    (!!_ctx.door?.action)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.door?.type === 'poem')
            ? (_openBlock(), _createBlock(_component_DoorCoCreation, {
                key: 0,
                door: _ctx.door,
                onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleAction(false))),
                onAccept: _cache[4] || (_cache[4] = ($event: any) => (_ctx.door.type = 'leadgame'))
              }, null, 8, ["door"]))
            : (_ctx.door?.type === 'leadgame')
              ? (_openBlock(), _createBlock(_component_DoorLeadFamily, {
                  key: 1,
                  gameId: _ctx.door?.game_id,
                  src: _ctx.door?.game_url,
                  onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleAction(false)))
                }, null, 8, ["gameId", "src"]))
              : (_ctx.door?.type === 'user_mgn')
                ? (_openBlock(), _createBlock(_component_DoorMGM, {
                    key: 2,
                    door: _ctx.door,
                    onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleAction(false))),
                    onPoints: _cache[7] || (_cache[7] = ($event: any) => {
          _ctx.toggleAction(false);
          _ctx.togglePrices(true);
        })
                  }, null, 8, ["door"]))
                : (_ctx.door?.type === 'podcast')
                  ? (_openBlock(), _createBlock(_component_DoorPodcast, {
                      key: 3,
                      door: _ctx.door,
                      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.toggleAction(false))),
                      onPoints: _cache[9] || (_cache[9] = ($event: any) => {
          _ctx.toggleAction(false);
          _ctx.togglePrices(true);
        })
                    }, null, 8, ["door"]))
                  : (_ctx.door?.type === 'wyng')
                    ? (_openBlock(), _createBlock(_component_DoorWyng, {
                        key: 4,
                        onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.toggleAction(false))),
                        onNotnow: _cache[11] || (_cache[11] = ($event: any) => {
          _ctx.toggleAction(false);
          _ctx.togglePrices(true);
        })
                      }))
                    : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.prices)
      ? (_openBlock(), _createBlock(_component_Prices, {
          key: 4,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.togglePrices(false)))
        }))
      : _createCommentVNode("", true)
  ]))
}