
import { defineComponent, PropType } from 'vue';
import { Podcast } from '@/types';
import { Door } from '../../types';
import DoorWrapper from '../DoorWrapper.vue';
import AudioPodcastVue from '@/components/AudioPodcast/AudioPodcast.vue';

export default defineComponent({
  components: {
    AudioPodcastVue,
    DoorWrapper,
  },
  props: {
    door: {
      type: Object as PropType<Door>,
      required: true,
    },
  },
  emits: ['close', 'points'],
  data() {
    return {
      points: false as boolean,
      shouldPlay: false as boolean,
      isPaused: true as boolean,
      t: null,
      idref: '' as string,
      podcast: {} as Podcast,
    };
  },
  computed: {
    CTA() {
      return this.isPaused
        ? this.t.doors.podcast.play
        : this.t.doors.podcast.pause;
    },
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  mounted() {
    this.idref = this.door.header;
    this.podcast = {
      audioUrl: this.door.url,
      name: this.door.header,
      trackingAction: this.door.action_id,
      uid: this.door.header,
      image: require('@/modules/advent/assets/img/together-do.svg'),
    };
  },
  unmounted() {
    this.points = false;
  },
  methods: {
    closeDoor(src?: string): void {
      this.$emit(this.points ? 'points' : src === 'ended' ? '' : 'close');
    },
    togglePlay(): void {
      if (!!this.shouldPlay) {
        this.$refs.player.$el.paused
          ? this.$refs.player.$el.play()
          : this.$refs.player.$el.pause();
        this.isPaused = this.$refs.player.$el.paused;
        return;
      }
      this.shouldPlay = true;
      this.isPaused = false;
    },
    getPoints() {
      this.$store
        .dispatch('adventModule/consumedContent', this.podcast.trackingAction)
        .then(() => {
          this.points = this.$store.state.adventModule.door.points !== '';
        });
    },
  },
});
