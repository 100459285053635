
import { defineComponent } from 'vue';

import Button from '@/components/Button/Button.vue';

import { Door } from '../types';
import DoorLeadFamily from './DoorTypes/LeadFamily.vue';
import DoorMGM from './DoorTypes/MGM.vue';
import DoorVideo from './DoorTypes/Video.vue';
import DoorUGC from './DoorTypes/UGC.vue';
import Prices from './Prices.vue';

import 'vue3-lottie/dist/style.css';

export default defineComponent({
  components: {
    Button,
    DoorLeadFamily,
    DoorMGM,
    DoorUGC,
    DoorVideo,
    Prices,
  },
  emits: ['close'],
  data() {
    const today = new Date().getDate() as number

    return {
      today,
      door: {
        action: false,
        open: false,
        day: null,
      } as Door,
      prices: false as boolean,
      isCloseConfirmationShown: false,
    };
  },
  computed: {
    showDoor() {
      return !this.door?.open && !this.door?.action && !this.prices;
    },
    isDoorAlreadyOpened() {
      const calendarDoor = this.$store.state.adventModule2023?.calendar[this.door.day]
      return calendarDoor?.state === 'opened_in_past' && calendarDoor?.door_content?.type !== 'leadgame';
    },
    isDataReady() {
      return !!this.$store.state.adventModule2023?.calendar?.[this.door.day];
    }
  },
  mounted() {
      this.door = { ...this.door, ...this.$store.state.adventModule2023.door };
      this.door.day = this.today;
      this.toggleDoor(true);
  },
  methods: {
    toggleDoor(state: boolean, { skipPopup } = { skipPopup: false }): void {
      this.door.open = state;
      if(!state) {
        if(skipPopup) {
          this.goBackToHome()
        } else {
          this.isCloseConfirmationShown = true;
        }
      }
    },
    goBackToHome(): void {
      this.$emit('close')
    },
    togglePrices(state: boolean): void {
      this.prices = state;
    },
    claimPoints(actionId) {
      /**
       * UGV/WYNG and MGM get the points automatically on /openDoor api call. No need to call `claimPoints()`.
       * Playable games call the claim points on LeadFamily's end, so we don't need to `claimPoints()` either.
       */
      this.$store
        .dispatch('adventModule2023/consumedContent', actionId)
    }
  },
});
