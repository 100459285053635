import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a2b1df4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-iframe flex flex-col items-center justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeadfamilyGameVue = _resolveComponent("LeadfamilyGameVue")!
  const _component_DoorWrapper = _resolveComponent("DoorWrapper")!

  return (_openBlock(), _createBlock(_component_DoorWrapper, {
    fullSize: _ctx.fullSize,
    onClose: _ctx.closeDoor
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.gameCssClass, "iframe-wrapper"])
        }, [
          _createVNode(_component_LeadfamilyGameVue, { gameURL: _ctx.src }, null, 8, ["gameURL"])
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["fullSize", "onClose"]))
}