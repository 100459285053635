import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-692c4a96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-iframe h-full" }
const _hoisted_2 = { class: "iframe-wrapper w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeadfamilyGame = _resolveComponent("LeadfamilyGame")!
  const _component_DoorWrapper = _resolveComponent("DoorWrapper")!

  return (_openBlock(), _createBlock(_component_DoorWrapper, {
    class: "lead-family",
    fullSize: "",
    onClose: _ctx.closeDoor
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LeadfamilyGame, { gameURL: _ctx.src }, null, 8, ["gameURL"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}