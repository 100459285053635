import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3b89f0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card pt-64 md:pt-72 lg:pt-80" }
const _hoisted_2 = { class: "bg-primary-soft-white flex flex-col items-center" }
const _hoisted_3 = { class: "card-intro p-8 md:px-24 mx-auto text-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-wrap gap-8 p-8 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Survey = _resolveComponent("Survey")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ContentBlock, {
          title: _ctx.t?.card[0].hl,
          text: _ctx.t?.card[0].cp,
          cssClass: "variant-card"
        }, null, 8, ["title", "text"]),
        _createElementVNode("img", {
          class: "object-contain",
          src: require(`../assets/img/postcard_16x9_${_ctx.isDesktop ? 'desktop': 'mobile'}.png`),
          alt: "postcard"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "w-48",
            theme: "dark",
            outline: "",
            href: _ctx.t?.card[0].buttons[0].href,
            alternateHover: "",
            download: "IQOS-Advent-Postcard.png"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t?.card[0].buttons[0].cp), 1)
            ]),
            _: 1
          }, 8, ["href"]),
          (_ctx.webShareCapable)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "w-48",
                theme: "dark",
                outline: "",
                href: _ctx.t?.card[0].buttons[1].href,
                alternateHover: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.webShare()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t?.card[0].buttons[1].cp), 1)
                ]),
                _: 1
              }, 8, ["href"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (_ctx.showSurvey)
      ? (_openBlock(), _createBlock(_component_Survey, {
          key: 0,
          title: _ctx.t?.survey.hl,
          text: _ctx.t?.survey.cp,
          href: _ctx.t?.survey.href,
          onClose: _ctx.closeSurvey
        }, null, 8, ["title", "text", "href", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}