import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e569376"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-primary-slate flex items-center flex-col px-8 md:px-10 py-5 md:pb-8 md:w-1/2 lg:w-1/3" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "heading-1 font-bold mt-4 md:mt-5 mb-4" }
const _hoisted_4 = { class: "copy--small text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require(`@/modules/advent/assets/img/${_ctx.image}`),
      alt: _ctx.title,
      class: "w-12 md:w-16"
    }, null, 8, _hoisted_2),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1)
  ]))
}