
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from './DoorWrapper.vue';
import { Door } from '../types';

export default defineComponent({
  components: {
    Button,
    DoorWrapper,
  },
  emits: ['close'],
  data() {
    return {
      t: null,
      door: {} as Door,
      prizeCopies: {
        video: {
          text: 'Werde auch Du Teil der IQOS Community und teile Deinen funkelnden IQOS Winter-Moment.',
          cta: 'Zur Community Wall',
          href: 'https://de.iqosclub.com/iqos-community',
        },
        user_mgn: {
          text: `Deine Punkte werden Dir automatisch gutgeschrieben.
            Morgen wartet wieder ein einzigartiger Gewinn auf Dich!<br><br>
            Erfahre hier mehr, wie Du IQOS Deinen Freund*innnen empfehlen kannst.`,
          cta: 'Zum Empfehlungsprogramm',
          href: 'https://www.iqosclub.com/de/de/loyalty/dashboard/iqos-club-news/mgm.html',
        },
        wyng: {
          text: `Deine Punkte werden Dir automatisch gutgeschrieben.
            Morgen wartet wieder ein einzigartiger Gewinn auf Dich!<br><br>
            Lasse Dich von anderen Mitgliedern inspirieren und entdecke, was sie bereits geteilt haben. `,
          cta: 'Zur Community Wall',
          href: 'https://de.iqosclub.com/iqos-community',
        }
      },
    };
  },
  computed: {
    shouldDisplayDisclaimer() {
      return !this.prizeCopies[this.door?.type];
    },
    isVideoDoor() {
      return this.door?.type === 'video';
    },
    isMGMDoor() {
      return this.door?.type === 'user_mgn';
    },
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
  mounted() {
    this.door = this.$store.state.adventModule2023.door;
  },
  unmounted() {
    this.$store.commit('adventModule2023/SET_POINTS', '');
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
  },
});
