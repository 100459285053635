import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-887e47f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prices flex flex-col lg:flex-row-reverse items-start pb-8 md:mx-8 lg:mx-auto" }
const _hoisted_2 = { class: "lg:w-5/12" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "text-white bg-primary-slate p-8 md:p-14 lg:p-20 lg:w-7/12" }
const _hoisted_5 = { class: "flex flex-wrap lg:mx-auto md:pr-14 lg:pr-8 lg:pl-4" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/modules/advent/assets/img/home/hauptgewinn-image-${_ctx.imageSize}.jpg`),
        alt: _ctx.title,
        class: "w-full"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", {
          class: "title-big font-bold mt-6 md:mt-0",
          innerHTML: _ctx.title
        }, null, 8, _hoisted_6),
        _createElementVNode("div", {
          class: "copy mb-8 lg:mb-10 mt-5",
          innerHTML: _ctx.text
        }, null, 8, _hoisted_7),
        (_ctx.button)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "mb-2",
              theme: "light",
              outline: _ctx.button,
              onClick: _ctx.gotoTop
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.a.cp), 1)
              ]),
              _: 1
            }, 8, ["outline", "onClick"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "hint pt-6",
          innerHTML: _ctx.hint
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}