import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-593491bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper h-full px-8 md:px-24 lg:px-60 pt-60 lg:pt-32 pb-20 md:pb-72 lg:pb-12 flex justify-center" }
const _hoisted_2 = { class: "content bg-primary-soft-white w-full p-6 md:p-24 flex flex-wrap items-center justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full pt-5" }
const _hoisted_5 = { class: "content-slot mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/modules/advent/assets/img/step3.svg`),
        class: "w-16"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ])
  ]))
}