import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8364fe0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[
      _ctx.dark ? 'text-white bg-primary-slate' : 'text-primary-slate',
      _ctx.cssClass,
    ], "flex flex-col items-center pb-8"])
  }, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.image,
          alt: _ctx.title,
          class: "image"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("h1", {
      class: "font-bold w-full",
      innerHTML: _ctx.title
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "content mt-4",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_3),
    (_ctx.a)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.a, (btn, i) => {
          return (_openBlock(), _createBlock(_component_Button, {
            key: btn + i,
            class: _normalizeClass(_ctx.button ? '' : 'px-0 pb-0 border-b rounded-none self-start'),
            theme: _ctx.dark || _ctx.buttonDark ? 'light' : 'dark',
            outline: _ctx.button,
            href: btn.href,
            alternateHoverWhite: !_ctx.button
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(btn.cp), 1)
            ]),
            _: 2
          }, 1032, ["class", "theme", "outline", "href", "alternateHoverWhite"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}