
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    button: {
      type: Boolean,
      default: false,
    },
    a: {
      type: Object,
    },
  },
  computed: {
    imageSize() {
      if (this.$mq === 'sm' || this.$mq === 'md')
        return 'S';
      else if (this.$mq === 'lg')
        return 'M';
      return 'L';
    }
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
});
