import { ResponsiveImageSet } from '@/types';

export const generateResponsiveImage = (
  alt: string,
  srcBase: string,
  srcBaseMobile?: string,
): ResponsiveImageSet => ({
  alt,
  mobile: {
    x1: require(`./assets/img/${srcBaseMobile || srcBase}@1x.jpg`),
    x2: require(`./assets/img/${srcBaseMobile || srcBase}@2x.jpg`),
    x3: require(`./assets/img/${srcBaseMobile || srcBase}@3x.jpg`),
  },
  desktop: {
    x1: require(`./assets/img/${srcBase}@1x.jpg`),
    x2: require(`./assets/img/${srcBase}@2x.jpg`),
    x3: require(`./assets/img/${srcBase}@3x.jpg`),
  },
});
