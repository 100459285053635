
import { defineComponent } from 'vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from '../DoorWrapper.vue';
import { generateResponsiveImage } from '@/modules/advent2023/utils';

export default defineComponent({
  components: {
    Button,
    DoorWrapper,
    ResponsiveImage,
  },
  emits: ['close', 'notnow', 'mounted'],
  data() {
    return {
      t: null,
      src: '' as string,
      image: generateResponsiveImage('UGC - Presents', 'ugc')
    };
  },
  computed: {
    door() {
      return this.$store.state.adventModule2023.door;
    },
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
  mounted(){
    this.$emit('mounted');
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
    notNow() {
      this.$emit('notnow');
    },
  },
});
