import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentBlock = _resolveComponent("ContentBlock")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!

  return (_openBlock(), _createBlock(_component_ContentWrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_ContentBlock, {
        class: "text-center",
        title: _ctx.t?.maintenance.hl,
        text: _ctx.t?.maintenance.cp,
        a: _ctx.t?.maintenance.a,
        cssClass: "maintenance",
        buttonDark: "",
        button: ""
      }, null, 8, ["title", "text", "a"])
    ]),
    _: 1
  }))
}