import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_CloseBtn = _resolveComponent("CloseBtn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["door-wrapper text-primary-soft-white min-h-screen h-full flex flex-1 flex-col content-end w-full md:flex-none md:flex-row md:items-center md:justify-center", _ctx.fullSize ? 'items-stretch' : null])
  }, [
    _createVNode(_component_ResponsiveImage, {
      class: "absolute inset-0",
      responsiveImageSet: _ctx.backgroundImage,
      imageClasses: "w-full h-full object-cover object-right-bottom"
    }, null, 8, ["responsiveImageSet"]),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.fullSize ? '' : 'py-9 px-4 md:px-8 lg:px-24', "door-content-box min-h-screen h-full bg-primary-soft-white flex flex-col items-center md:w-2/3 lg:w-1/2 mt-auto lg:mt-0 relative"])
    }, [
      _createVNode(_component_ResponsiveImage, {
        class: "absolute inset-0 z-0",
        responsiveImageSet: _ctx.challengeBackgroundImage,
        imageClasses: "w-full h-full object-cover object-left-bottom lg:object-top-right"
      }, null, 8, ["responsiveImageSet"]),
      (_ctx.closeable)
        ? (_openBlock(), _createBlock(_component_CloseBtn, {
            key: 0,
            light: "",
            class: "z-20",
            onClose: _ctx.closeDoor
          }, null, 8, ["onClose"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.fullSize ? 'w-full md:w-full flex-grow' : 'md:w-2/3 mb-24', "door-content relative lg:w-full h-full z-10"])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ], 2)
  ], 2))
}