
import { defineComponent } from 'vue';
import Calendar from '../components/Calendar.vue';
import ContentBlock from '../components/ContentBlock.vue';
import PricesBlockHome from '../components/PricesBlockHome.vue';
import Step from '../components/Step.vue';

export default defineComponent({
  components: {
    Calendar,
    ContentBlock,
    PricesBlockHome,
    Step,
  },
  data() {
    return {
      t: null,
      fullCal: false as boolean,
    };
  },
  computed: {
    isDektop() {
      return this.$mq.includes('xl');
    },
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  methods: {
    toggleFullscreen(e: boolean): void {
      this.fullCal = e;
      window.scrollTo({ top: 0, behavior: 'auto' });
    },
  },
});
