
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from '../DoorWrapper.vue';

export default defineComponent({
  components: {
    Button,
    DoorWrapper,
  },
  emits: ['close', 'notnow'],
  data() {
    return {
      t: null,
      src: '' as string,
    };
  },
  computed: {
    door() {
      return this.$store.state.adventModule.door;
    },
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
    notNow() {
      if (this.door.points) {
        this.$emit('notnow');
        return;
      }
      window.open(this.t?.doors.wyng.a[1].href, '_self');
    },
  },
});
