
import { defineComponent } from 'vue';
import ContentWrapper from '../components/ContentWrapper.vue';
import ContentBlock from '../components/ContentBlock.vue';

export default defineComponent({
  components: {
    ContentWrapper,
    ContentBlock,
  },
  data() {
    return {
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
});
