
import { defineComponent, PropType, ref, computed } from 'vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import Icon from '@/components/Icon/Icon.vue';
import { ResponsiveImageSet } from '@/types';
import { RouteRecordRaw } from 'vue-router';

type Slide = {
  image: ResponsiveImageSet;
  text: string;
  link?: string | RouteRecordRaw;
}

export default defineComponent({
  components: {
    Icon,
    ResponsiveImage,
  },
  props: {
    teasers: {
      type: Object as PropType<Slide[]>,
      required: true,
    },
    gap: {
      type: Number,
      default: 16,
    },
    size: {
      type: Number,
      default: 312,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
    darken: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const space = computed(() => props.size + props.gap); // slide width + margin right
    const current = ref(0);

    const next = () => {
      current.value =
        current.value <= props.teasers.length - 2
          ? current.value + 1
          : current.value;
    };
    const prev = () => {
      current.value = current.value > 0 ? current.value - 1 : current.value;
    };

    return {
      current,
      next,
      prev,
      space,
    };
  },
});
