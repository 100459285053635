
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
export default defineComponent({
  components: {
    Button,
  },
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    button: {
      type: Boolean,
      default: false,
    },
    a: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    buttonDark: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: String,
      default: '',
    },
  },
});
