
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from '../DoorWrapper.vue';

export default defineComponent({
  components: {
    DoorWrapper,
    Button,
  },
  props: {
    door: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'skip', 'mounted'],
  data() {
    return {
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
    copyCode() {
      this.trackEvent('XMAS_MGM_Copy')
      navigator.clipboard.writeText(this.door.mgm);
    },
    skip() {
      this.$emit('skip');
    },
    addCodeToLink(link: string) {
      return link.replace('{mgmcode}', this.door.mgm);
    },
    trackEvent(action) {
      this.$store.dispatch('adventModule2023/sendCustomAction', action)
    }
  },
});
