
import { defineComponent } from 'vue';

export default defineComponent({
  components: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    image: {
      type: String,
    },
  },
});
