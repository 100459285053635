
import { defineComponent, PropType } from 'vue';
import { Door } from '../types';
import Button from '@/components/Button/Button.vue';
import DoorWrapper from './DoorWrapper.vue';

export default defineComponent({
  components: {
    Button,
    DoorWrapper,
  },
  props: {
    door: {
      type: Object as PropType<Door>,
      required: true,
    },
  },
  emits: ['action', 'close'],
  data() {
    return {
      t: null,
    };
  },
  created() {
    this.t = this.$store.state.adventModule.t;
  },
  methods: {
    closeDoor() {
      this.$emit('close');
    },
    startAction() {
      this.$emit('action');
    },
  },
});
