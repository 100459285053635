
import { routeNames } from '../router';
import { defineComponent } from 'vue';
import ContentWrapper from '@/modules/advent2023/components/ContentWrapper.vue';
import ContentBlock from '@/modules/advent2023/components/ContentBlock.vue';
import BackToClubButton from '@/modules/advent2023/components/BackToClubButton.vue';

const UNITS = {
  minute: { duration: 60000 },
  hour: { duration: 3600000 },
  day: { duration: 86400000 },
};

const FINAL_DATE = new Date(2023, 11, 1, 0, 0, 0);

export default defineComponent({
  components: {
    ContentWrapper,
    ContentBlock,
    BackToClubButton,
  },
  data() {
    return {
      t: null,
      timeDifference: null,
      jsInterval: null,
    };
  },
  computed: {
    countdown() {
      return this.getCountdown();
    },
  },
  created() {
    this.t = this.$store.state.adventModule2023.t;
  },
  mounted() {
    this.updateTimeDifference();
    this.jsInterval = setInterval(this.updateTimeDifference, 5 * 1000);
  },
  unmounted() {
    clearInterval(this.jsInterval);
  },
  methods: {
    format2Digit(value: number) {
      return value.toString().padStart(2, '0');
    },
    getCountdown() {
      let currentTime = this.timeDifference;
      let days = 0;
      let hours = 0;
      let minutes = 0;
      if (currentTime > UNITS.day.duration) {
        days = Math.floor(currentTime / UNITS.day.duration);
        currentTime -= days * UNITS.day.duration;
      }
      if (currentTime > UNITS.hour.duration) {
        hours = Math.floor(currentTime / UNITS.hour.duration);
        currentTime -= hours * UNITS.hour.duration;
      }
      if (currentTime > UNITS.minute.duration) {
        minutes = Math.floor(currentTime / UNITS.minute.duration);
      }
      return {
        days: this.format2Digit(days),
        hours: this.format2Digit(hours),
        minutes: this.format2Digit(minutes + 1),
      };
    },
    updateTimeDifference() {
      const now = new Date();
      this.timeDifference = FINAL_DATE.getTime() - now.getTime();
      if(this.timeDifference <= 0) this.$router.push({ name: routeNames.ADVENT_HOME })
    },
  },
});
