import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-120a24c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full flex flex-col items-center justify-center space-y-8" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex flex-wrap items-center justify-center w-full gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DoorWrapper = _resolveComponent("DoorWrapper")!

  return (_openBlock(), _createBlock(_component_DoorWrapper, { onClose: _ctx.closeDoor }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", {
          class: "heading-1 copy-wrapper font-bold text-center",
          innerHTML: _ctx.door.header
        }, null, 8, _hoisted_2),
        _createElementVNode("p", {
          class: "copy copy-wrapper text-center",
          innerHTML: _ctx.door.body
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            class: "w-48",
            theme: "light",
            outline: "",
            href: _ctx.door.url
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.door.link_name || _ctx.t?.doors.wyng.a[0].cp), 1)
            ]),
            _: 1
          }, 8, ["href"]),
          _createVNode(_component_Button, {
            class: "w-48",
            theme: "dark",
            outline: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notNow()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t?.doors.wyng.a[1].cp), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}